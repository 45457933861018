import { withTranslation, WithTranslation } from "react-i18next";
import { useRef } from "react";
import world from "../../assets/world.webp";
import shipculture1 from "../../assets/shipculture_1.png";
import shipculture2 from "../../assets/shipculture_2.png";
import h4h1 from "../../assets/h4h_1.png";
import h4h2 from "../../assets/h4h_2.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Projects = ({ t }: WithTranslation) => {
  const latestProjectRef = useRef<HTMLDivElement | null>(null);
  const firstProjectRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const offset = 70;
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="projects">
      <div className="projects__figure">
        <LazyLoadImage
          className="projects__image"
          src={world}
          alt="world"
          effect="blur"
          width="100%"
          height="100%"
        />
        <h1 className="projects__title">{t("projects")}</h1>
      </div>
      <div className="projects__main">
        <div className="projects__container">
          <div className="projects__options">
            <button
              className="projects__button"
              onClick={() => scrollToSection(latestProjectRef)}
            >
              {t("latest_project")}
            </button>
            <button
              className="projects__button"
              onClick={() => scrollToSection(firstProjectRef)}
            >
              {t("first_project")}
            </button>
          </div>
          <div className="projects__wrapper">
            <div ref={latestProjectRef} className="projects__wrapperChild">
              <div className="projects__box">
                <div className="projects__wrapperPhoto">
                  <LazyLoadImage
                    className="projects__photo"
                    src={shipculture1}
                    alt="shipculture1"
                    effect="blur"
                  />
                </div>
                <div className="projects__wrapperPhoto">
                  <LazyLoadImage
                    wrapperProps={{
                      style: {
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                      },
                    }}
                    className="projects__photo"
                    src={shipculture2}
                    alt="shipculture2"
                    effect="blur"
                  />
                </div>
              </div>
              <Link
                className="projects__link"
                to="https://shipculture-eae76.web.app/"
              >
                https://shipculture-eae76.web.app/
              </Link>
              <div className="projects__descriptionWrapp">
                <p className="projects__descriptionApp">
                  {t("marine_project_app")}
                </p>
              </div>
              <div>
                <p className="projects__descriptionApp projects__descriptionApp--line">
                  {t("app_benefits")}
                </p>
                <ul className="projects__list">
                  <li className="projects__item">{t("timely_maintenance")}</li>
                  <li className="projects__item">{t("increased_trust")}</li>
                  <li className="projects__item">
                    {t("increased_efficiency")}
                  </li>
                  <li className="projects__item">{t("innovative_methods")}</li>
                </ul>
              </div>
            </div>

            <div ref={firstProjectRef} className="projects__wrapperChild">
              <div className="projects__box">
                <div className="projects__wrapperPhoto">
                  <LazyLoadImage
                    className="projects__photo"
                    src={h4h1}
                    alt="h4h1"
                    effect="blur"
                    style={{ filter: "blur(10px)" }}
                  />
                </div>
                <div className="projects__wrapperPhoto">
                  <LazyLoadImage
                    className="projects__photo"
                    src={h4h2}
                    alt="h4h2"
                    effect="blur"
                    style={{ filter: "blur(5px)" }}
                  />
                </div>
              </div>
              <div className="projects__descriptionWrapp">
                <p className="projects__descriptionApp" style={{ filter: "blur(5px)" }}>
                  {t("helpforhelp_app")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withTranslation()(Projects);
